import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col } from "react-bootstrap"

const Introuvable = ({ location }) => {
	return (
		<Layout location={location}>
			<SEO title="404 - Introuvable" />
			<Row>
				<Col>
					<h2>Erreur 404 - Page introuvable</h2>
				</Col>
			</Row>
		</Layout>
	)
}

export default Introuvable


